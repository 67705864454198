import { render, staticRenderFns } from "./DefaultNavigationBar.vue?vue&type=template&id=e62e0b90&scoped=true&"
import script from "./DefaultNavigationBar.vue?vue&type=script&lang=ts&"
export * from "./DefaultNavigationBar.vue?vue&type=script&lang=ts&"
import style0 from "./DefaultNavigationBar.vue?vue&type=style&index=0&id=e62e0b90&prod&lang=scss&scoped=true&"
import style1 from "./DefaultNavigationBar.vue?vue&type=style&index=1&id=e62e0b90&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e62e0b90",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LinkButton: require('/opt/build/repo/apps/shop/components/storyblok/LinkButton/LinkButton.vue').default})
